import { ApiError } from '@om1/joint-insights-api'
import { notificationActions } from '@om1/platform-notifications'
import { put } from 'redux-saga/effects'

export function* handleApiError(error: unknown) {
    const apiError: ApiError = error as ApiError
    // Log the error for debugging purposes
    if (process.env.NODE_ENV !== 'test') {
        console.log(JSON.stringify(apiError))
    }
    let formattedErrorMessage
    try {
        formattedErrorMessage = apiError.body['detail']
            .replace(/\\n/g, ' ') // Replace newline characters with spaces
            .replace(/"/g, '"') // Keep double quotes as they are
    } catch (e) {
        formattedErrorMessage = ''
    }

    switch (apiError.status) {
        case 401:
            // Session expired or unauthorized access
            // yield put(keycloakActions.sessionExpired())
            yield put(notificationActions.error('Session Expired'))
            break
        case 400:
            // Bad request
            yield put(notificationActions.error(`Bad Request: ${formattedErrorMessage}`))
            break
        case 403:
            // Forbidden access
            yield put(notificationActions.error(`Access Denied: ${formattedErrorMessage}`))
            break
        case 409:
            // Conflict
            yield put(notificationActions.error(`Conflict: ${formattedErrorMessage}`))
            break
        case 404:
            // Resource not found
            yield put(notificationActions.warning(`Resource Not Found: ${formattedErrorMessage}`))
            break
        case 423:
            // Locked
            yield put(notificationActions.error(`Locked: ${formattedErrorMessage}`))
            break
        case 429:
            // Rate limit reached
            yield put(notificationActions.error('Rate limit exceeded'))
            break
        case 500:
            // Internal server error
            yield put(notificationActions.error(`Internal Server Error: ${formattedErrorMessage}`))
            break
        case 503:
            // Service unavailable
            yield put(notificationActions.error(`Service Unavailable: ${formattedErrorMessage}`))
            break
        default:
            // General error handler for other statuses
            yield put(notificationActions.error(apiError.body || 'An unexpected error occurred'))
            break
    }
}
